import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { go } from '../../lib/utils';
import { getHomePhoto } from '../../api/home';

import { SectionTitle } from './SectionTitle';
import { Row, Col, Wrapper, GridWrapper, Body2 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';

interface IImageProps {
    image: string;
}

const PHOTO_COUNT = 8;

const ThumbnailRow = styled(Row)<IImageProps>`
    width: 295px;
    height: 295px;
    border-radius: 6px;
    ${({ image }) => image && `background-image: url('${image}');`}
    background-position: center;
    background-size: cover;
    justify-content: flex-end;
`;

const ItemRow = styled(Row)`
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ProfileImageCol = styled(Col)<IImageProps>`
    width: 28px;
    height: 28px;
    border: 1px solid ${COLOR_V4.GRAY200};
    border-radius: 14px;
    ${({ image }) => image && `background-image: url('${image}');`}
    background-position: center;
    background-size: cover;
`;

function PhotoList() {
    const [photoList, setPhotoList] = useState([]);

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getYoutubeList() {
            const returnData = await getHomePhoto();
            setPhotoList(returnData);
        }

        getYoutubeList();
    }, []);

    if (!photoList) {
        return null;
    }

    return (
        <Wrapper overscroll={'contain'} padding={'50px 0 36px'}>
            <GridWrapper>
                <SectionTitle
                    title={'사진으로 이웃집 구경하기'}
                    isShowButton={true}
                    onClickEvent={() => {
                        go('/picture?window_title=공간별사진&hide_menu=true');
                    }}
                />
                <ItemRow>
                    {photoList.map((story: any, index: number) => {
                        const { itemIdx, imageIdx, imageUrl = '', authorName, profile } = story;

                        if (PHOTO_COUNT > index) {
                            return (
                                <Col
                                    width={'295px'}
                                    key={`photo-item-${itemIdx}`}
                                    cursor={'pointer'}
                                    onClick={() => {
                                        go(`/picture/view/${imageIdx}`);
                                    }}
                                >
                                    <ThumbnailRow image={imageUrl} />
                                    <Row margin={'13px 0 32px'}>
                                        <ProfileImageCol image={profile} />
                                        <Col width={'calc(100% - 38px)'} margin={'2px 0 0 10px'}>
                                            <Body2
                                                color={COLOR_V4.GRAY900}
                                                fontWeight={'regular'}
                                                maxHeight={'48px'}
                                                hideText={true}
                                            >
                                                {authorName} 님의 공간
                                            </Body2>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        }
                    })}
                </ItemRow>
            </GridWrapper>
        </Wrapper>
    );
}

export default PhotoList;
