import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
SwiperCore.use([Pagination]);

import { getHomePromotion } from '../../api/home';
import { go } from '../../lib/utils';

import { Row, Col, Wrapper, Button, Body1 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';
import ProductCardXLarge, { ViewType } from '../product/ProductCard/ProductCardXLarge';
import { PromotionInfo } from '../../api/commerce';

interface HomePromotionInfo extends PromotionInfo {
    pcImageUrl: string;
}

interface IPromotionProps {
    idx: number;
}

const PromotionWrapper = styled(Wrapper)`
    .swiper-container {
        width: 100%;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute !important;
        width: 56px !important;
        height: 56px !important;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        z-index: 200;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-prev {
        left: calc(50% - 575px);
        background-image: url('//cdn.ggumim.co.kr/storage/20220211143528AFjSIUozHV.png');
    }

    .swiper-button-next {
        right: calc(50% - 575px);
        background-image: url('//cdn.ggumim.co.kr/storage/20220211143535Zl7tJyODJP.png');
    }

    .swiper-pagination {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190px;
        height: 30px;
        left: unset !important;
        right: calc(50% + 310px);
        top: 412px;
        z-index: 100;

        .swiper-pagination-bullet {
            width: 9px;
            height: 9px;
            margin: 0 5px;
            background: rgba(255, 255, 255, 0.4) !important;
            opacity: 0.5;
        }

        .swiper-pagination-bullet-active {
            background: ${COLOR_V4.WHITE} !important;
            opacity: 1;
        }
    }
`;

const ItemWrapper = styled.div<{
    image: string;
}>`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 52px 0;
    ${({ image }) => image && `background-image: url('${image}');`}
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
`;

function Promotion(props: IPromotionProps) {
    const router = useRouter();
    const [promotionList, setPromotionList] = useState<HomePromotionInfo[]>([]);

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getPromotionList() {
            const returnData: HomePromotionInfo[] = await getHomePromotion(props.idx);
            setPromotionList(returnData);
        }

        getPromotionList();
    }, []);

    if (!promotionList || promotionList.length === 0) {
        return <></>;
    }

    return (
        <PromotionWrapper>
            <Swiper
                spaceBetween={0}
                speed={800}
                slidesPerView={1}
                loop={true}
                navigation={true}
                pagination={true}
            >
                {promotionList.map((promotionItem) => {
                    const { idx, commercePromotionIdx, pcImageUrl, products = [] } = promotionItem;

                    return (
                        <SwiperSlide key={`promotion-${idx}`}>
                            <ItemWrapper image={pcImageUrl}>
                                <Row width={'1000px'} justify={'space-between'}>
                                    <Col
                                        width={'235px'}
                                        height={'auto'}
                                        borderRadius={'4px'}
                                        overflow={'hidden'}
                                    >
                                        <Button
                                            width={'190px'}
                                            height={'48px'}
                                            margin={'300px 0 0'}
                                            border={`1px solid ${COLOR_V4.WHITE}`}
                                            borderRadius={'0'}
                                            background={'transparent'}
                                            onClick={() => {
                                                if (commercePromotionIdx) {
                                                    router.push(
                                                        `/commerce/promotion/view/${commercePromotionIdx}?show_store_nav=true&prev_path=promotion/view/${idx}`,
                                                    );
                                                } else {
                                                    go(`/promotion/view/${idx}`);
                                                }
                                            }}
                                        >
                                            <Body1 color={COLOR_V4.WHITE}>
                                                기획전 상품 전체보기
                                            </Body1>
                                        </Button>
                                    </Col>

                                    {products.map((product, index) => {
                                        // PC 기획전은 3개만 보여줘야함
                                        if (index >= 3) {
                                            return (
                                                <Fragment
                                                    key={`promotion-product-${product.idx}`}
                                                />
                                            );
                                        }

                                        return (
                                            <ProductCardXLarge
                                                key={`promotion-product-${product.idx}`}
                                                product={product}
                                                viewType={ViewType.GRID}
                                            />
                                        );
                                    })}
                                </Row>
                            </ItemWrapper>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </PromotionWrapper>
    );
}

export default Promotion;
