import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';
import { Row, Wrapper } from '../layout';
import { PRODUCT_HORIZONTAL_GAP } from '../product/ProductCard';
import ProductCardXLarge, { ViewType } from '../product/ProductCard/ProductCardXLarge';
import { SectionTitle } from './';

import { IProduct } from '../../api/commerce';
import { getHomeSalesList } from '../../api/home';

import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
SwiperCore.use([Navigation]);

interface ISwiperButtonProps {
    direction: string;
    disabled: boolean;
}

const SalesListWrapper = styled(Wrapper)`
    padding: 100px 0 30px;
    margin: -68px 0 0;
    background: ${COLOR_V4.GRAY50};

    .swiper-container {
        width: 1150px;
        padding: 20px 75px;
    }

    .swiper-wrapper {
        margin: 0 auto;
    }

    .swiper-slide {
        width: 235px;
    }
`;

const SwiperButton = styled.div<ISwiperButtonProps>`
    display: flex;
    align-items: center;
    width: 70px;
    height: 468px;
    position: absolute !important;
    margin-top: calc(-425px);
    z-index: 200;
    background: ${COLOR_V4.GRAY50};

    .icon {
        width: 56px !important;
        height: 56px !important;
        background-position: center;
        background-size: cover;
        border-radius: 28px;
        ${({ disabled }) => (disabled ? `opacity: 0` : 'cursor: pointer')};
    }

    ${({ direction }) =>
        direction === 'right' &&
        `
        right: calc(50% - 575px);
        justify-content: flex-end;

        .right-icon {
            background-image: url("//cdn.ggumim.co.kr/storage/20220211143535Zl7tJyODJP.png");
        }
    `}

    ${({ direction }) =>
        direction === 'left' &&
        `
        left: calc(50% - 575px);

        .left-icon {
            background-image: url("//cdn.ggumim.co.kr/storage/20220211143528AFjSIUozHV.png");
        }
    `}
`;

function SalesList() {
    const router = useRouter();

    const [salesList, setSalesList] = useState<IProduct[]>([]);
    const [swiperIndex, setSwiperIndex] = useState(0);
    const [swiperRef, setSwiperRef] = useState<any>(null);

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getSalesList() {
            const returnData = await getHomeSalesList();
            setSalesList(returnData);
        }

        getSalesList();
    }, []);

    if (!salesList || salesList.length === 0) {
        return <SalesListWrapper></SalesListWrapper>;
    }

    const onClickSwiper = (index: number) => {
        swiperRef ? swiperRef?.slideTo(index, 400) : undefined;
    };

    return (
        <SalesListWrapper>
            <SectionTitle
                padding={'0'}
                width={'1000px'}
                title={'집꾸핫딜'}
                isShowButton={true}
                onClickEvent={() => {
                    router.push(
                        `/commerce/promotion/view/1?show_store_nav=true&prev_path=promotion/view/987`,
                    );
                }}
            />
            <Row width={'100%'}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={PRODUCT_HORIZONTAL_GAP}
                    loop={false}
                    onSwiper={setSwiperRef}
                    onActiveIndexChange={(swiper: SwiperClass) => {
                        setSwiperIndex(swiper.realIndex);
                    }}
                >
                    <SwiperButton
                        direction={'left'}
                        disabled={swiperIndex <= 0}
                        onClick={() => onClickSwiper(swiperIndex - 4)}
                    >
                        <div className={'icon left-icon'}></div>
                    </SwiperButton>
                    <SwiperButton
                        direction={'right'}
                        disabled={swiperIndex + 4 >= salesList.length}
                        onClick={() => onClickSwiper(swiperIndex + 4)}
                    >
                        <div className={'icon right-icon'}></div>
                    </SwiperButton>
                    {salesList.map((product: IProduct) => (
                        <SwiperSlide key={`hotdeal-product-${product.idx}`}>
                            <ProductCardXLarge product={product} viewType={ViewType.GRID} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Row>
        </SalesListWrapper>
    );
}

export default SalesList;
