// React Lib
import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);
// Lib
import { go } from '../../lib/utils';
import { PromotionBanner } from '../../api/home';
import { COLOR_V4 } from '../../lib/styles/colors';

import Amplitude from 'amplitude-js';

// Interface
interface IBannerSliderProps {
    bannerList: PromotionBanner[];
}
interface IPaginationProps {
    type?: 'bullets' | 'fraction' | 'progressbar' | 'custom';
    renderFraction?: (currentClass: string, totalClass: string) => void;
}
// Custom Component
const SlickImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1440px;
    height: 360px;
    overflow: hidden;
    z-index: 100;

    img {
        min-width: 1440px;
        width: auto;
        height: 360px;
        object-fit: cover;
    }
`;

const BannerSliderWrapper = styled.div`
    width: 100%;
    min-width: 1440px;
    overflow: hidden;
    cursor: pointer;

    .swiper-pagination {
        position: absolute;
        width: 106px;
        height: 30px;
        left: unset !important;
        right: calc(50% - 480px);
        bottom: 80px;
        padding-top: 4px;
        border-radius: 8px;
        background: ${COLOR_V4.DIM1};
        color: ${COLOR_V4.WHITE};
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.06em;
        z-index: 100;

        .swiper-pagination-button {
            color: ${COLOR_V4.WHITE};
        }

        .swiper-pagination-current {
            padding-right: 1px;
            font-weight: bold;
        }

        .swiper-pagination-total {
            padding-left: 1px;
        }
    }

    .swiper-button-prev {
        background-image: url('//cdn.ggumim.co.kr/storage/20220208104935mT0xgV70nB.png');
    }

    .swiper-button-next {
        background-image: url('//cdn.ggumim.co.kr/storage/20220208104941EoZovrWF1M.png');
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute !important;
        top: auto !important;
        bottom: 84px !important;
        width: 24px !important;
        height: 24px !important;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        z-index: 200;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-prev {
        left: auto !important;
        right: calc(50% - 480px + 80px) !important;
    }

    .swiper-button-next {
        left: auto !important;
        right: calc(50% - 480px) !important;
    }
`;

function BannerSlider({ bannerList = [] }: IBannerSliderProps) {
    const router = useRouter();

    if (!bannerList || bannerList.length === 0) {
        return null;
    }

    const pagination: IPaginationProps = {
        type: 'fraction',
        renderFraction: () => {
            return `
                <button class="swiper-pagination-button"  onClick={location.href='/home/promotion'}>
                    <span
                        data-amplitude-event="Home_Banner"
                        data-amplitude-object='{"Type":"전체보기 (+버튼)"}'
                        class="swiper-pagination-current"
                        onClick={location.href='/home/promotion'}
                    ></span>
                        /
                    <span
                        data-amplitude-event="Home_Banner"
                        data-amplitude-object='{"Type":"전체보기 (+버튼)"}'
                        class="swiper-pagination-total"
                        onClick={location.href='/home/promotion'}
                    ></span>
                </button>
            `;
        },
    };

    return (
        <BannerSliderWrapper>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={600}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                navigation={true}
                pagination={pagination}
            >
                {bannerList.map((banner: any, index: number) => (
                    <SwiperSlide key={`home-banner-${index}`}>
                        <SlickImage
                            onClick={() => {
                                Amplitude.getInstance().logEvent('Home_Banner', {
                                    Type: '배너',
                                    Index: index + 1,
                                    Title: banner.name,
                                });
                                gtag('event', 'home_banner', {
                                    type: '배너',
                                    index: index + 1,
                                    title: banner.name,
                                });
                                if (banner.itemType === 'web') {
                                    window.open(banner.landingUrl);
                                } else if (
                                    banner.itemType === 'promotion' &&
                                    banner.commercePromotionIdx
                                ) {
                                    router.push(
                                        `/commerce/promotion/view/${banner.commercePromotionIdx}?show_store_nav=true&prev_path=promotion/view/${banner.itemIdx}`,
                                    );
                                } else {
                                    go(banner.landingUrl);
                                }
                            }}
                        >
                            <img src={banner.imageUrl} />
                        </SlickImage>
                    </SwiperSlide>
                ))}
            </Swiper>
        </BannerSliderWrapper>
    );
}

export default BannerSlider;
