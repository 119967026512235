import { useEffect } from 'react';
import { useGlobalModal, Modals } from '../../lib/hooks/GlobalModal';
import Toast, { ToastType } from '../../components/layout/Toast';
import { destroyCookie, parseCookies } from 'nookies';

function CouponPopup(props: { memberIdx: string | number }) {
    const { openModal, closeModal } = useGlobalModal();

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        if (localStorage.getItem('isLogin') !== null) {
            Toast.show({
                message: '로그인이 완료되었습니다.',
                type: ToastType.success,
                bottom: '20px',
            });

            localStorage.removeItem('isLogin');
        }

        const cookies = parseCookies();
        const cookieName = `issued_coupon_${props.memberIdx}`;

        if (cookies[cookieName]) {
            openModal(Modals.CouponModal, {
                close: () => closeModal(Modals.CouponModal),
            });
            destroyCookie(null, cookieName);
        }
    }, []);

    return <></>;
}

export default CouponPopup;
