import React, { useEffect, useState } from 'react';

import { getHomePopupStore } from '../../api/home';
import { Product } from '../../api/search';

import { COLOR_V4 } from '../../lib/styles/colors';
import { Wrapper, GridWrapper, GridBox } from '../layout';
import { SectionTitle } from './SectionTitle';
import ProductCardXLarge from '../product/ProductCard/ProductCardXLarge';
import { PRODUCT_HORIZONTAL_GAP } from '../product/ProductCard';

import Amplitude from 'amplitude-js';

interface IPopupStoreProps {
    idx: number;
    index: number;
}

interface IPopupStoreState {
    idx: number;
    displayType: string;
    name?: string;
    products: any[];
}

function PopupStore(props: IPopupStoreProps) {
    const [popupStore, setPopupStore] = useState<IPopupStoreState>();
    const backgroundColorList = [
        COLOR_V4.GRADIENT1,
        COLOR_V4.GRADIENT3,
        COLOR_V4.GRADIENT4,
        COLOR_V4.GRADIENT2,
        COLOR_V4.GRADIENT5,
        COLOR_V4.GRADIENT1,
        COLOR_V4.GRADIENT3,
        COLOR_V4.GRADIENT4,
        COLOR_V4.GRADIENT2,
    ];

    const { idx, index } = props;

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getPopupList() {
            const returnData = await getHomePopupStore(idx);
            setPopupStore(returnData);
        }

        getPopupList();
    }, []);

    if (!popupStore || !popupStore?.products || popupStore?.products.length === 0) {
        return null;
    }

    const { name, displayType, products } = popupStore;

    return (
        <Wrapper overscroll={'contain'}>
            <GridWrapper
                padding={'30px 0 50px'}
                borderTop={index !== 0 ? `1px solid ${COLOR_V4.GRAY200}` : ''}
            >
                <SectionTitle
                    title={name}
                    buttonBackground={backgroundColorList[index - 1]}
                    isShowTooltip={true}
                />
                <GridBox
                    width={'1000px'}
                    templateColumns={'repeat(4, 1fr)'}
                    gap={`${PRODUCT_HORIZONTAL_GAP}px`}
                >
                    {products.map((product: Product, itemIndex: number) => {
                        // 팝업 스토어는 4개까지만 보여줘야 함
                        if (itemIndex >= 4) {
                            return null;
                        }

                        return (
                            <ProductCardXLarge
                                key={`popup-store-prodcut-${product.idx}`}
                                product={product}
                                handleClickProductCard={() => {
                                    Amplitude.getInstance().logEvent('Home_Popupstore', {
                                        Type: displayType,
                                        Index: itemIndex + 1,
                                        FurnitureIdx: product.idx,
                                        FurnitureName: product.name,
                                        PopupStoreName: popupStore.name,
                                    });
                                }}
                            />
                        );
                    })}
                </GridBox>
            </GridWrapper>
        </Wrapper>
    );
}

export default PopupStore;
