import React, { Component } from 'react';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';

import { IMember } from '../../stores/common/common';

import { Wrapper } from '../../components/layout';
import {
    BannerSlider,
    ShortCut,
    SalesList,
    Category,
    Promotion,
    PopupStore,
    HomeStory,
    YoutubeList,
    PhotoList,
    CouponPopup,
} from '../../components/home';

interface IViewProps extends WithRouterProps {
    member: IMember;
    homeView: any[];
}

class HomeView extends Component<IViewProps, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { homeView, member } = this.props;

        let popupStoreIndex = 0;

        return (
            <Wrapper>
                <CouponPopup memberIdx={member.idx} />
                {homeView.map((component: any, index: number) => {
                    const { name, data } = component;

                    switch (name) {
                        case 'promotion_banner':
                            return (
                                <BannerSlider key={`component-order-${index}`} bannerList={data} />
                            );
                        case 'shortcut':
                            return (
                                <ShortCut
                                    key={`component-order-${index}`}
                                    shortCut={data}
                                    member={member}
                                />
                            );
                        case 'today_promotion':
                            return <SalesList key={`component-order-${index}`} />;
                        case 'category_shortcut':
                            return (
                                <Category key={`component-order-${index}`} categoryList={data} />
                            );
                        case 'promotion':
                            return <Promotion key={`component-order-${index}`} idx={data} />;
                        case 'popup_store':
                            popupStoreIndex += 1;
                            return (
                                <PopupStore
                                    key={`component-order-${index}`}
                                    idx={data}
                                    index={popupStoreIndex}
                                />
                            );
                        case 'house_story':
                            return <HomeStory key={`component-order-${index}`} />;
                        case 'zipggu_tv':
                            return <YoutubeList key={`component-order-${index}`} />;
                        case 'space_photo':
                            return <PhotoList key={`component-order-${index}`} />;
                    }
                })}
            </Wrapper>
        );
    }
}

export default withRouter(HomeView);
