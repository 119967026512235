import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { Row } from '../layout';
import { Body2 } from './Text';
import { COLOR_V4 } from '../../lib/styles/colors';
import { replaceToLineBreak } from '../../lib/utils';

export enum ToastType {
    success = 'success',
    info = 'info',
    error = 'error',
    warning = 'warning',
}
export interface ToastOptions {
    message: string;
    type?: ToastType;
    duration?: number;
    containerSelector?: string;
    top?: string;
    bottom?: string;
}

export const DefaultToastDurationMs = 3000;
const AnimationDurationMs = 300;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.9;
    }
`;
const fadeOut = keyframes`
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
`;

const ToastWrapper = styled(Row)<{
    display?: string;
    position: string;
    top?: string;
    bottom?: string;
    finished: boolean;
}>`
    position: ${({ position }) => position};
    left: 50%;
    top: ${({ top }) => top || 'unset'};
    bottom: ${({ bottom }) => bottom || 'unset'};
    transform: translateX(-50%);
    box-sizing: border-box;
    width: 500px;
    background: ${COLOR_V4.GRAY500};
    opacity: 0.9;
    border-radius: 8px;
    z-index: 30000;
    ${({ finished }) =>
        finished
            ? css`
                  animation: ${fadeOut} ${AnimationDurationMs}ms forwards;
              `
            : css`
                  animation: ${fadeIn} ${AnimationDurationMs}ms;
              `}
`;

function _Toast({
    idx,
    message,
    duration = DefaultToastDurationMs,
    top,
    bottom = '72px',
    containerSelector,
}: ToastOptions & { idx: number }) {
    const [finished, setFinished] = useState<boolean>(false);
    const [displayNone, setDisplayNone] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setFinished(false);
            setDisplayNone(false);
        }, 100);

        const timer = setTimeout(() => {
            setFinished(true);

            setTimeout(() => {
                setDisplayNone(true);
            }, AnimationDurationMs);
        }, duration);

        return () => {
            setFinished(true);
            setDisplayNone(true);
            clearTimeout(timer);
        };
    }, [idx]);

    return (
        <ToastWrapper
            display={displayNone ? 'none' : 'flex'}
            position={containerSelector ? 'absolute' : 'fixed'}
            align={'center'}
            justify={'center'}
            top={top}
            bottom={bottom}
            padding={'14px 24px'}
            finished={finished}
        >
            <Body2
                color={COLOR_V4.WHITE}
                fontWeight={'regular'}
                dangerouslySetInnerHTML={{ __html: replaceToLineBreak(message) }}
            />
        </ToastWrapper>
    );
}

export default class Toast {
    private static idx = 1;
    private static toastContainerClassName = 'toast-container';

    static show({ message, type, duration, containerSelector, top, bottom }: ToastOptions) {
        const body = document.querySelector('body');
        if (body) {
            let container = document.querySelector(containerSelector || '#body-toast-container');
            if (!container) {
                const _container = document.createElement('div');
                _container.setAttribute('id', 'body-toast-container');
                const toastContainer = document.createElement('div');
                toastContainer.setAttribute('class', Toast.toastContainerClassName);
                _container.appendChild(toastContainer);

                const body = document.querySelector('body');
                if (!body) {
                    return;
                }
                body.appendChild(_container);

                ReactDOM.render(
                    <_Toast
                        idx={Toast.idx}
                        message={message}
                        type={type}
                        duration={duration}
                        top={top}
                        bottom={bottom}
                        containerSelector={containerSelector}
                    />,
                    toastContainer,
                );
            } else {
                let toastContainer = document.querySelector(
                    `${containerSelector || '#body-toast-container'} > .${
                        Toast.toastContainerClassName
                    }`,
                );
                if (!toastContainer) {
                    toastContainer = document.createElement('div');
                    toastContainer.setAttribute('class', Toast.toastContainerClassName);
                    container.appendChild(toastContainer);
                }

                ReactDOM.render(
                    <_Toast
                        idx={Toast.idx}
                        message={message}
                        type={type}
                        duration={duration}
                        top={top}
                        bottom={bottom}
                        containerSelector={containerSelector}
                    />,
                    toastContainer,
                );
            }

            Toast.idx += 1;
        }
    }

    static clearAll() {
        document
            .querySelectorAll(`.${Toast.toastContainerClassName}`)
            .forEach((element) => element.remove());
    }
}
