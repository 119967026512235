import React, { useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import SwiperCore, { Navigation } from 'swiper/core';
SwiperCore.use([Navigation]);

import { Row, Col, Wrapper, Body1 } from '../layout';
import { SectionTitle } from './SectionTitle';
import { COLOR_V4 } from '../../lib/styles/colors';

import Amplitude from 'amplitude-js';

interface ICategoryProps {
    categoryList: any[] | any;
}

interface ISwiperButtonProps {
    direction: string;
    disabled: boolean;
}

interface ICategoryItemProps {
    idx: number;
    name: string;
    imageUrl?: string;
}

const CategoryItem = styled(Col)`
    width: 85px;
    align-items: center;

    .category-button {
        width: 85px;
        height: 85px;
        padding: 14px 4px;
        border: 1px solid ${COLOR_V4.WHITE};
        border-radius: 52px;
        background: #e8effa;
        align-items: center;
        justify-content: center;

        img {
            display: flex;
            flex: 1;
        }
    }
`;

const CategoryWrapper = styled(Wrapper)`
    padding: 30px 0;

    .swiper-container {
        width: 1130px;
        padding: 20px 60px;
    }

    .swiper-slide {
        flex-shrink: inherit !important;
        width: unset !important;
        margin-right: 12px;
    }

    .swiper-slide:first-child {
        margin-left: 3px;
    }

    .swiper-slide:last-child {
        margin-right: 0;
    }
`;

const CategoryItemBorder = styled(Col)`
    width: 91px;
    height: 91px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    .category-button {
        z-index: 10;
    }

    .category-active {
        position: absolute;
        width: 89px;
        height: 89px;
        border-radius: 50%;
        background: ${COLOR_V4.GRADIENT4};
        opacity: 0;
        z-index: 0;
        transition: 0.5s;
    }

    &:hover {
        .category-active {
            opacity: 1;
        }
    }
`;

const SwiperButton = styled.div<ISwiperButtonProps>`
    position: absolute !important;
    display: flex;
    align-items: center;
    width: 95px;
    height: 160px;
    margin-top: -160px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 16%,
        #ffffff 26%
    );
    z-index: 200;

    .icon {
        width: 21px !important;
        height: 42px !important;
        background-position: center;
        background-size: cover;
        ${({ disabled }) => (disabled ? `opacity: 0` : 'cursor: pointer')};
    }

    ${({ direction }) =>
        direction === 'right' &&
        `
        right: calc(50% - 590px);
        justify-content: flex-end;

        .right-icon {
            margin-right: 26px;
            background-image: url("//cdn.ggumim.co.kr/storage/20220519164939Kev0zs3brQ.png");
        }
    `}

    ${({ direction }) =>
        direction === 'left' &&
        `
        left: calc(50% - 590px);
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 16%, #ffffff 26%);

        .left-icon {
            margin-left: 26px;
            background-image: url("//cdn.ggumim.co.kr/storage/20220519164928Dv7NKYIHCg.png");
        }
    `}
`;

function Category(props: ICategoryProps) {
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [swiperIndex, setSwiperIndex] = useState(0);

    const { categoryList = [] } = props;

    const onClickSwiper = (index: number) => {
        setSwiperIndex(index);
        swiperRef ? swiperRef?.slideTo(index, 400) : undefined;
    };

    return (
        <CategoryWrapper>
            <SectionTitle padding={'0'} width={'1000px'} title={'카테고리별 상품 찾기'} />
            <Row>
                <Swiper
                    slidesPerView={'auto'}
                    freeMode={true}
                    loop={false}
                    onSwiper={setSwiperRef}
                    onActiveIndexChange={(swiper: SwiperClass) => {
                        setSwiperIndex(swiper.realIndex);
                    }}
                >
                    <SwiperButton direction={'left'} disabled={swiperIndex <= 0}>
                        <div className={'icon left-icon'} onClick={() => onClickSwiper(0)}></div>
                    </SwiperButton>
                    <SwiperButton
                        direction={'right'}
                        disabled={swiperIndex + 11 >= categoryList.length}
                    >
                        <div
                            className={'icon right-icon'}
                            onClick={() => {
                                onClickSwiper(categoryList.length);
                            }}
                        ></div>
                    </SwiperButton>
                    {categoryList.map((item: ICategoryItemProps, index: number) => {
                        const { idx, name, imageUrl } = item;

                        return (
                            <SwiperSlide key={`home-category-${index}`}>
                                <Link href={`/furniture/more/${idx}?back_url=furniture`}>
                                    <CategoryItem
                                        onClick={() => {
                                            Amplitude.getInstance().logEvent(
                                                'Home_Categoryshortcut',
                                                {
                                                    Type: '스토어카테고리',
                                                    CategoryName: name,
                                                },
                                            );
                                        }}
                                    >
                                        <CategoryItemBorder>
                                            <div className={'category-active'}></div>
                                            <Row className={'category-button'}>
                                                <img src={imageUrl} alt={'category-image'} />
                                            </Row>
                                        </CategoryItemBorder>
                                        <Row margin={'10px 0 0'}>
                                            <Body1 color={COLOR_V4.GRAY900} fontWeight={'medium'}>
                                                {name}
                                            </Body1>
                                        </Row>
                                    </CategoryItem>
                                </Link>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Row>
        </CategoryWrapper>
    );
}

export default Category;
