import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Body2, Display4 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';

interface ISectionTitleProps {
    arrow?: boolean;
    width?: string;
    padding?: string;
    margin?: string;
    title?: string;
    buttonBackground?: string;
    isShowButton?: boolean;
    isShowTooltip?: boolean;
    onClickEvent?: () => void;
}

interface ITooltipColProps {
    isActive: boolean;
}

const TooltipCol = styled(Col)<ITooltipColProps>`
    height: 30px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-iteration-count: ${({ isActive }) => (isActive ? 4 : 0)};
    animation-iteration-count: ${({ isActive }) => (isActive ? 4 : 0)};

    @-webkit-keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            -webkit-transform: translateY(0);
        }
        40% {
            -webkit-transform: translateY(-15px);
        }
        60% {
            -webkit-transform: translateY(-10px);
        }
    }

    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-15px);
        }
        60% {
            transform: translateY(-10px);
        }
    }

    img {
        width: 70px;
    }
`;

export function SectionTitle({
    width = '100%',
    padding = '0 0 20px',
    margin = '0',
    title = '제목',
    buttonBackground = '',
    isShowButton = false,
    isShowTooltip = false,
    onClickEvent = () => console.log('Need onClick event'),
}: ISectionTitleProps) {
    const [animation, setAnimation] = useState<any>(false);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        if (isShowTooltip && !animation) {
            const onScroll = (_e: Event) => {
                let tooltipElementTop = 0;
                const tooltipElement = document.querySelector<Element>('.title-tooltip');

                if (tooltipElement instanceof HTMLElement) {
                    tooltipElementTop = tooltipElement?.offsetTop;
                }

                const scrollY = window.scrollY;

                if (tooltipElementTop >= scrollY + 10) {
                    setAnimation(true);
                }
            };

            window.addEventListener('scroll', onScroll);
            return () => window.removeEventListener('scroll', onScroll);
        }
    }, [animation]);

    return (
        <Row width={width} padding={padding} margin={margin} align={'center'}>
            <Row width={isShowButton ? '80%' : '100%'}>
                <Col margin={'0 10px 0 0'}>
                    <Display4 color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                        {title}
                    </Display4>
                </Col>
                {isShowTooltip && (
                    <TooltipCol className={'title-tooltip'} isActive={animation}>
                        <Button
                            height={'32px'}
                            margin={'0 0 0'}
                            padding={'2px 8px'}
                            borderRadius={'16px'}
                            background={buttonBackground}
                            cursor={'default'}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="66"
                                height="22"
                                viewBox="0 0 80 23"
                                fill="none"
                            >
                                <path
                                    d="M6.185 17.49C6.185 17.243 6.185 16.996 6.185 16.749C6.185 15.495 6.128 14.146 6.128 12.968C6.128 12.816 6.147 12.759 6.242 12.759C6.28 12.759 6.318 12.759 6.375 12.778C6.869 12.892 7.325 12.93 7.762 12.93C8.237 12.93 8.674 12.892 9.13 12.835C11.79 12.569 13.253 10.422 13.253 8.313C13.253 6.261 11.866 4.228 8.864 4.038C8.579 4.019 8.199 4 7.8 4C4.076 4 4.057 5.254 4.057 7.591C4.057 8.921 4.095 10.783 4.095 12.74C4.095 14.355 4.076 16.027 4 17.509C4 17.528 4 17.547 4 17.566C4 18.193 4.551 18.535 5.083 18.535C5.634 18.535 6.166 18.212 6.185 17.49ZM8.674 6.014C10.384 6.014 11.125 7.078 11.125 8.218C11.125 9.434 10.251 10.745 8.807 10.878C8.408 10.916 8.009 10.935 7.648 10.935C6.47 10.935 6.09 10.802 6.09 10.403C6.09 10.289 6.09 10.194 6.09 10.08C6.09 9.415 6.071 8.636 6.071 7.971C6.071 6.204 6.242 5.995 7.762 5.995C8.218 5.995 8.674 6.014 8.674 6.014Z"
                                    fill="white"
                                />
                                <path
                                    d="M22.2807 18.744C26.4607 18.744 29.6147 15.799 29.6147 11.372C29.6147 7.097 26.4607 4 22.2807 4C18.1007 4 14.9657 7.097 14.9657 11.372C14.9657 15.799 18.1007 18.744 22.2807 18.744ZM22.2807 16.749C19.4687 16.749 17.1887 14.64 17.1887 11.372C17.1887 8.085 19.4687 5.957 22.2807 5.957C25.0927 5.957 27.3727 8.085 27.3727 11.372C27.3727 14.64 25.0927 16.749 22.2807 16.749Z"
                                    fill="white"
                                />
                                <path
                                    d="M34.2864 17.49C34.2864 17.243 34.2864 16.996 34.2864 16.749C34.2864 15.495 34.2294 14.146 34.2294 12.968C34.2294 12.816 34.2484 12.759 34.3434 12.759C34.3814 12.759 34.4194 12.759 34.4764 12.778C34.9704 12.892 35.4264 12.93 35.8634 12.93C36.3384 12.93 36.7754 12.892 37.2314 12.835C39.8914 12.569 41.3544 10.422 41.3544 8.313C41.3544 6.261 39.9674 4.228 36.9654 4.038C36.6804 4.019 36.3004 4 35.9014 4C32.1774 4 32.1584 5.254 32.1584 7.591C32.1584 8.921 32.1964 10.783 32.1964 12.74C32.1964 14.355 32.1774 16.027 32.1014 17.509C32.1014 17.528 32.1014 17.547 32.1014 17.566C32.1014 18.193 32.6524 18.535 33.1844 18.535C33.7354 18.535 34.2674 18.212 34.2864 17.49ZM36.7754 6.014C38.4854 6.014 39.2264 7.078 39.2264 8.218C39.2264 9.434 38.3524 10.745 36.9084 10.878C36.5094 10.916 36.1104 10.935 35.7494 10.935C34.5714 10.935 34.1914 10.802 34.1914 10.403C34.1914 10.289 34.1914 10.194 34.1914 10.08C34.1914 9.415 34.1724 8.636 34.1724 7.971C34.1724 6.204 34.3434 5.995 35.8634 5.995C36.3194 5.995 36.7754 6.014 36.7754 6.014Z"
                                    fill="white"
                                />
                                <path
                                    d="M48.0533 14.83C48.6993 14.83 49.0223 14.298 49.0223 13.766C49.0223 13.234 48.6993 12.721 48.0723 12.721C48.0533 12.721 48.0533 12.721 48.0343 12.721C47.1223 12.74 46.0963 12.759 45.1083 12.759C44.3103 12.759 43.5313 12.74 42.8663 12.721C42.8473 12.721 42.8473 12.721 42.8283 12.721C42.2393 12.721 41.9353 13.253 41.9353 13.766C41.9353 14.222 42.2013 14.868 42.9043 14.83C43.6833 14.792 44.2533 14.773 44.8233 14.773C45.6593 14.773 46.4953 14.811 48.0343 14.83H48.0533Z"
                                    fill="white"
                                />
                                <path
                                    d="M57.1243 18.725C59.9173 18.725 62.9573 16.749 62.8623 12.607C62.8433 11.41 62.8243 10.346 62.8243 9.263C62.8243 8.028 62.8433 6.736 62.8813 5.159C62.8813 5.14 62.8813 5.14 62.8813 5.121C62.8813 4.475 62.3303 4.152 61.7793 4.152C61.3993 4.152 60.6583 4.304 60.6773 5.159C60.6963 5.976 60.7153 6.812 60.7153 7.648C60.7153 9.32 60.6773 11.011 60.6773 12.664C60.6773 15.248 58.8343 16.597 57.0483 16.597C55.3193 16.597 53.6093 15.362 53.6093 12.816C53.6093 12.797 53.6093 12.778 53.6093 12.759C53.6093 12.512 53.6093 12.284 53.6093 12.037C53.6093 10.498 53.5713 8.94 53.5713 7.382C53.5713 6.641 53.5903 5.9 53.6093 5.159C53.6093 5.14 53.6093 5.14 53.6093 5.121C53.6093 4.475 53.0583 4.152 52.5263 4.152C51.7663 4.152 51.4053 4.608 51.4243 5.159C51.4623 6.261 51.4623 7.401 51.4623 8.56C51.4623 9.947 51.4623 11.353 51.4433 12.683C51.4433 12.721 51.4433 12.74 51.4433 12.778C51.4433 16.749 54.2743 18.725 57.1243 18.725Z"
                                    fill="white"
                                />
                                <path
                                    d="M68.0797 17.49C68.0797 17.243 68.0797 16.996 68.0797 16.749C68.0797 15.495 68.0227 14.146 68.0227 12.968C68.0227 12.816 68.0417 12.759 68.1367 12.759C68.1747 12.759 68.2127 12.759 68.2697 12.778C68.7637 12.892 69.2197 12.93 69.6567 12.93C70.1317 12.93 70.5687 12.892 71.0247 12.835C73.6847 12.569 75.1477 10.422 75.1477 8.313C75.1477 6.261 73.7607 4.228 70.7587 4.038C70.4737 4.019 70.0937 4 69.6947 4C65.9707 4 65.9517 5.254 65.9517 7.591C65.9517 8.921 65.9897 10.783 65.9897 12.74C65.9897 14.355 65.9707 16.027 65.8947 17.509C65.8947 17.528 65.8947 17.547 65.8947 17.566C65.8947 18.193 66.4457 18.535 66.9777 18.535C67.5287 18.535 68.0607 18.212 68.0797 17.49ZM70.5687 6.014C72.2787 6.014 73.0197 7.078 73.0197 8.218C73.0197 9.434 72.1457 10.745 70.7017 10.878C70.3027 10.916 69.9037 10.935 69.5427 10.935C68.3647 10.935 67.9847 10.802 67.9847 10.403C67.9847 10.289 67.9847 10.194 67.9847 10.08C67.9847 9.415 67.9657 8.636 67.9657 7.971C67.9657 6.204 68.1367 5.995 69.6567 5.995C70.1127 5.995 70.5687 6.014 70.5687 6.014Z"
                                    fill="white"
                                />
                                <path
                                    d="M6.185 17.49C6.185 17.243 6.185 16.996 6.185 16.749C6.185 15.495 6.128 14.146 6.128 12.968C6.128 12.816 6.147 12.759 6.242 12.759C6.28 12.759 6.318 12.759 6.375 12.778C6.869 12.892 7.325 12.93 7.762 12.93C8.237 12.93 8.674 12.892 9.13 12.835C11.79 12.569 13.253 10.422 13.253 8.313C13.253 6.261 11.866 4.228 8.864 4.038C8.579 4.019 8.199 4 7.8 4C4.076 4 4.057 5.254 4.057 7.591C4.057 8.921 4.095 10.783 4.095 12.74C4.095 14.355 4.076 16.027 4 17.509C4 17.528 4 17.547 4 17.566C4 18.193 4.551 18.535 5.083 18.535C5.634 18.535 6.166 18.212 6.185 17.49ZM8.674 6.014C10.384 6.014 11.125 7.078 11.125 8.218C11.125 9.434 10.251 10.745 8.807 10.878C8.408 10.916 8.009 10.935 7.648 10.935C6.47 10.935 6.09 10.802 6.09 10.403C6.09 10.289 6.09 10.194 6.09 10.08C6.09 9.415 6.071 8.636 6.071 7.971C6.071 6.204 6.242 5.995 7.762 5.995C8.218 5.995 8.674 6.014 8.674 6.014Z"
                                    stroke="white"
                                />
                                <path
                                    d="M22.2807 18.744C26.4607 18.744 29.6147 15.799 29.6147 11.372C29.6147 7.097 26.4607 4 22.2807 4C18.1007 4 14.9657 7.097 14.9657 11.372C14.9657 15.799 18.1007 18.744 22.2807 18.744ZM22.2807 16.749C19.4687 16.749 17.1887 14.64 17.1887 11.372C17.1887 8.085 19.4687 5.957 22.2807 5.957C25.0927 5.957 27.3727 8.085 27.3727 11.372C27.3727 14.64 25.0927 16.749 22.2807 16.749Z"
                                    stroke="white"
                                />
                                <path
                                    d="M34.2864 17.49C34.2864 17.243 34.2864 16.996 34.2864 16.749C34.2864 15.495 34.2294 14.146 34.2294 12.968C34.2294 12.816 34.2484 12.759 34.3434 12.759C34.3814 12.759 34.4194 12.759 34.4764 12.778C34.9704 12.892 35.4264 12.93 35.8634 12.93C36.3384 12.93 36.7754 12.892 37.2314 12.835C39.8914 12.569 41.3544 10.422 41.3544 8.313C41.3544 6.261 39.9674 4.228 36.9654 4.038C36.6804 4.019 36.3004 4 35.9014 4C32.1774 4 32.1584 5.254 32.1584 7.591C32.1584 8.921 32.1964 10.783 32.1964 12.74C32.1964 14.355 32.1774 16.027 32.1014 17.509C32.1014 17.528 32.1014 17.547 32.1014 17.566C32.1014 18.193 32.6524 18.535 33.1844 18.535C33.7354 18.535 34.2674 18.212 34.2864 17.49ZM36.7754 6.014C38.4854 6.014 39.2264 7.078 39.2264 8.218C39.2264 9.434 38.3524 10.745 36.9084 10.878C36.5094 10.916 36.1104 10.935 35.7494 10.935C34.5714 10.935 34.1914 10.802 34.1914 10.403C34.1914 10.289 34.1914 10.194 34.1914 10.08C34.1914 9.415 34.1724 8.636 34.1724 7.971C34.1724 6.204 34.3434 5.995 35.8634 5.995C36.3194 5.995 36.7754 6.014 36.7754 6.014Z"
                                    stroke="white"
                                />
                                <path
                                    d="M48.0533 14.83C48.6993 14.83 49.0223 14.298 49.0223 13.766C49.0223 13.234 48.6993 12.721 48.0723 12.721C48.0533 12.721 48.0533 12.721 48.0343 12.721C47.1223 12.74 46.0963 12.759 45.1083 12.759C44.3103 12.759 43.5313 12.74 42.8663 12.721C42.8473 12.721 42.8473 12.721 42.8283 12.721C42.2393 12.721 41.9353 13.253 41.9353 13.766C41.9353 14.222 42.2013 14.868 42.9043 14.83C43.6833 14.792 44.2533 14.773 44.8233 14.773C45.6593 14.773 46.4953 14.811 48.0343 14.83H48.0533Z"
                                    stroke="white"
                                />
                                <path
                                    d="M57.1243 18.725C59.9173 18.725 62.9573 16.749 62.8623 12.607C62.8433 11.41 62.8243 10.346 62.8243 9.263C62.8243 8.028 62.8433 6.736 62.8813 5.159C62.8813 5.14 62.8813 5.14 62.8813 5.121C62.8813 4.475 62.3303 4.152 61.7793 4.152C61.3993 4.152 60.6583 4.304 60.6773 5.159C60.6963 5.976 60.7153 6.812 60.7153 7.648C60.7153 9.32 60.6773 11.011 60.6773 12.664C60.6773 15.248 58.8343 16.597 57.0483 16.597C55.3193 16.597 53.6093 15.362 53.6093 12.816C53.6093 12.797 53.6093 12.778 53.6093 12.759C53.6093 12.512 53.6093 12.284 53.6093 12.037C53.6093 10.498 53.5713 8.94 53.5713 7.382C53.5713 6.641 53.5903 5.9 53.6093 5.159C53.6093 5.14 53.6093 5.14 53.6093 5.121C53.6093 4.475 53.0583 4.152 52.5263 4.152C51.7663 4.152 51.4053 4.608 51.4243 5.159C51.4623 6.261 51.4623 7.401 51.4623 8.56C51.4623 9.947 51.4623 11.353 51.4433 12.683C51.4433 12.721 51.4433 12.74 51.4433 12.778C51.4433 16.749 54.2743 18.725 57.1243 18.725Z"
                                    stroke="white"
                                />
                                <path
                                    d="M68.0797 17.49C68.0797 17.243 68.0797 16.996 68.0797 16.749C68.0797 15.495 68.0227 14.146 68.0227 12.968C68.0227 12.816 68.0417 12.759 68.1367 12.759C68.1747 12.759 68.2127 12.759 68.2697 12.778C68.7637 12.892 69.2197 12.93 69.6567 12.93C70.1317 12.93 70.5687 12.892 71.0247 12.835C73.6847 12.569 75.1477 10.422 75.1477 8.313C75.1477 6.261 73.7607 4.228 70.7587 4.038C70.4737 4.019 70.0937 4 69.6947 4C65.9707 4 65.9517 5.254 65.9517 7.591C65.9517 8.921 65.9897 10.783 65.9897 12.74C65.9897 14.355 65.9707 16.027 65.8947 17.509C65.8947 17.528 65.8947 17.547 65.8947 17.566C65.8947 18.193 66.4457 18.535 66.9777 18.535C67.5287 18.535 68.0607 18.212 68.0797 17.49ZM70.5687 6.014C72.2787 6.014 73.0197 7.078 73.0197 8.218C73.0197 9.434 72.1457 10.745 70.7017 10.878C70.3027 10.916 69.9037 10.935 69.5427 10.935C68.3647 10.935 67.9847 10.802 67.9847 10.403C67.9847 10.289 67.9847 10.194 67.9847 10.08C67.9847 9.415 67.9657 8.636 67.9657 7.971C67.9657 6.204 68.1367 5.995 69.6567 5.995C70.1127 5.995 70.5687 6.014 70.5687 6.014Z"
                                    stroke="white"
                                />
                            </svg>
                        </Button>
                    </TooltipCol>
                )}
            </Row>
            {isShowButton && (
                <Col width={'20%'} align={'flex-end'} onClick={onClickEvent}>
                    <Button
                        width={'102px'}
                        height={'40px'}
                        border={`1px solid ${COLOR_V4.GRAY300}`}
                        borderRadius={'20px'}
                        background={'transparent'}
                    >
                        <Body2 color={COLOR_V4.GRAY900}>
                            전체 보기 &nbsp;
                            <img
                                src="//cdn.ggumim.co.kr/storage/20220125161623fq3YqUg2VL.png"
                                alt=""
                                width={6}
                            />
                        </Body2>
                    </Button>
                </Col>
            )}
        </Row>
    );
}
