/* Package */
import { withRouter } from 'next/router';
import { Component } from 'react';
// Store
import wrapper from '../stores';
// Api
import { getHomeData } from '../api/home';
// Lib
import { TRACKID } from '../lib/values';
/* Container */
import Home from '../containers/home/index';

class HomePage extends Component<any, any> {
    static getInitialProps = wrapper.getInitialPageProps((store) => async () => {
        const homeView = await getHomeData('pc');
        const { member } = store.getState().common;

        if (homeView) {
            return {
                member,
                homeView,
            };
        }

        return {
            member,
            homeView: [],
        };
    });

    constructor(props: any) {
        super(props);
    }

    render() {
        const { member = null, homeView = [] } = this.props;

        return (
            <>
                <script
                    type="text/javascript"
                    charSet="UTF-8"
                    src="//t1.daumcdn.net/adfit/static/kp.js"
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            kakaoPixel('${TRACKID}').pageView();
                        `,
                    }}
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Varela+Round&display=swap"
                    rel="stylesheet"
                />
                <Home member={member} homeView={homeView} />
            </>
        );
    }
}

export default withRouter(HomePage);
