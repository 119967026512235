import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getHomeStory } from '../../api/home';
import { go } from '../../lib/utils';

import { SectionTitle } from './SectionTitle';
import { Row, Col, Text, Wrapper, GridWrapper, Body3 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';

interface IHomeStoryProps {
    mainArticle: any | null;
    articles: any[] | any;
}

interface IImageProps {
    image: string;
    width?: string | number;
    height?: string | number;
    after?: boolean;
}

interface IArticleProps {
    itemIdx: number;
    memberIdx: number;
    imageUrl: string;
    name: string;
    authorName: string;
    profile: string;
    newContent: boolean;
}

const ImageRow = styled(Row)<IImageProps>`
    width: 100%;
    ${({ image }) => image && `background-image: url('${image}');`}
    background-position: center;
    background-size: cover;
    border-radius: 7px;

    ${({ after }) =>
        after &&
        `
        &:after {
            content: "NEW";
            display: flex;
            position: relative;
            top: 12px;
            left: 13px;
            height: 23px;
            padding: 4px 7px 0px;
            border-radius: 3px;
            background: ${COLOR_V4.PINK500};
            text-align: center;
            color: ${COLOR_V4.WHITE};
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
        }
    `}
`;

const ItemRow = styled(Row)`
    width: 100%;
    flex-wrap: wrap;

    & > ${Col} {
        width: calc(33.3% - 13.3px);

        &:nth-child(3n - 1) {
            margin: 0 20px;
        }
    }
`;

const ProfileImageCol = styled(Col)<IImageProps>`
    width: ${({ width }) => width || '24px'};
    height: ${({ height }) => height || '24px'};
    border: 0.5px solid #e9ebee;
    border-radius: 16px;
    ${({ image }) => image && `background-image: url('${image}');`}
    background-position: center;
    background-size: cover;
`;

const TitleText = styled(Text)`
    color: #333c45;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    max-height: 26px;
    letter-spacing: -0.02em;
    word-wrap: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;

    & > img {
        width: 15px;
        height: 15px;
        margin-left: 2px;
        margin-bottom: -2px;
    }
`;

function HomeStory() {
    const [homeStory, setHomeStory] = useState<IHomeStoryProps>({
        mainArticle: null,
        articles: [],
    });

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getStory() {
            const returnData = await getHomeStory();
            setHomeStory(returnData);
        }

        getStory();
    }, []);

    if (!homeStory) {
        return <></>;
    }

    const { articles = [] } = homeStory;

    return (
        <Wrapper overscroll={'contain'} padding={'20px 0 26px'}>
            <GridWrapper>
                <SectionTitle
                    title={'집 이야기'}
                    isShowButton={true}
                    onClickEvent={() => {
                        go(
                            `/star/?sort=&type=&size=&style=&window_title=이웃집들이&hide_menu=true`,
                        );
                    }}
                />
                {/*<Row
                    width={"100%"}
                    height={"310px"}
                    margin={"0 0 20px"}
                    cursor={"pointer"}
                    onClick={() => {
                        go(`/star/view/${mainArticle.itemIdx}`);
                    }}
                >
                    <ThumbnailRow image={mainArticle?.imageUrl}>
                        <Row className={"article-description"}>
                            <ProfileImageCol
                                width={"32px"}
                                height={"32px"}
                                image={mainArticle?.profile || ""}
                            />
                            <Col className={"article-name"}>
                                <Text>
                                    {mainArticle?.authorName || ""}
                                </Text>
                            </Col>
                        </Row>
                    </ThumbnailRow>
                </Row>*/}
                <ItemRow>
                    {articles.map((article: IArticleProps) => {
                        const {
                            itemIdx,
                            memberIdx,
                            name,
                            imageUrl,
                            newContent,
                            authorName,
                            profile,
                        } = article;

                        return (
                            <Col width={'50%'} key={`home-story-${itemIdx}`}>
                                <ImageRow
                                    image={imageUrl}
                                    width={'100%'}
                                    height={'295px'}
                                    after={newContent}
                                    cursor={'pointer'}
                                    onClick={() => {
                                        go(`/star/view/${itemIdx}`);
                                    }}
                                />
                                <Row
                                    margin={'12px 0 0'}
                                    cursor={'pointer'}
                                    onClick={() => {
                                        go(`/star/view/${itemIdx}`);
                                    }}
                                >
                                    <TitleText>{name.replace('<br>', '')}</TitleText>
                                </Row>
                                <Row
                                    margin={'6px 0 24px'}
                                    cursor={'pointer'}
                                    onClick={() => go(`/member/view/${memberIdx}`)}
                                >
                                    <ProfileImageCol image={profile} />
                                    <Col height={'24px'} justify={'center'} margin={'0 0 0 8px'}>
                                        <Body3 color={COLOR_V4.GRAY500}>{authorName}</Body3>
                                    </Col>
                                </Row>
                            </Col>
                        );
                    })}
                </ItemRow>
            </GridWrapper>
        </Wrapper>
    );
}

export default HomeStory;
