import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Row, Col, Text, Button, Body2, Body4, Heading1 } from '../layout';

import { addNicknameEllipsis, go } from '../../lib/utils';
import { IMember } from '../../stores/common/common';
import { COLOR_V4 } from '../../lib/styles/colors';

import Amplitude from 'amplitude-js';

interface IShortCutProps {
    shortCut: [];
    member: IMember;
}

interface IShortCutItemProps {
    name: string;
    imageUrl: string;
    path: string;
}

const ShortCutContainer = styled(Row)`
    position: relative;
    width: 1000px;
    height: 128px;
    margin: -60px auto 0;
    border-radius: 26px;
    background: ${COLOR_V4.WHITE};
    box-shadow: ${COLOR_V4.SHADOW};
    z-index: 100;

    @media (max-width: 1000px) {
        left: 0;
    }
`;

const ShortCutIcon = styled.div`
    width: 66px;
    height: 66px;
    border-radius: 50%;
    cursor: pointer;

    img {
        width: 66px;
        border-radius: 50%;
    }
`;

const GrayText = styled(Text)`
    color: ${COLOR_V4.GRAY400};
    font-size: 13px;
    line-height: 37px;

    img {
        width: 20px;
        height: 20px;
        margin: 0 3px -6px 0;
    }
`;

const SectionRow = styled(Row)`
    flex-grow: 1;
    justify-content: space-around;

    ${Text} {
        font-size: 18px;
        font-weight: bold;
        line-height: 37px;
    }

    ${GrayText} {
        color: ${COLOR_V4.GRAY400};
        font-size: 13px;
        font-weight: normal;
    }

    &:first-child {
        img {
            margin-bottom: -4px;
        }
    }
`;

const MemberThumbnail = styled(Col)`
    width: 36px;
    height: 36px;
    margin: 0 10px 0 0;
    border: 1px solid ${COLOR_V4.GRAY200};
    border-radius: 18px;
    overflow: hidden;

    img {
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }
`;

const ShortCut = ({ member, shortCut = [] }: IShortCutProps) => {
    const router = useRouter();
    const { idx, nickname, gradeName, couponCount, point, recentProfile } = member;

    return (
        <ShortCutContainer>
            <Col width={'calc(50% - 1px)'} margin={'19px 31px 0'}>
                <Row justify={'space-between'}>
                    {shortCut.map((item: IShortCutItemProps, index) => {
                        const { name, imageUrl, path } = item;

                        return (
                            <Col key={`home-shortcut-${index}`} width={'68px'} align={'center'}>
                                <Row
                                    margin={'0 0 6px'}
                                    onClick={() => {
                                        Amplitude.getInstance().logEvent('Home_Shortcut', {
                                            Type: name,
                                        });

                                        if (name == '집꾸TV') {
                                            window.open(
                                                'https://www.youtube.com/c/%EC%A7%91%EA%BE%B8%EB%AF%B8%EA%B8%B0%EC%9C%A0%ED%8A%9C%EB%B8%8C',
                                                '_blank',
                                            );
                                        } else {
                                            go(path);
                                        }
                                    }}
                                >
                                    <ShortCutIcon>
                                        <img src={imageUrl} alt="" />
                                    </ShortCutIcon>
                                </Row>
                                <Row>
                                    <Body2 color={COLOR_V4.GRAY900} fontWeight={'medium'}>
                                        {name}
                                    </Body2>
                                </Row>
                            </Col>
                        );
                    })}
                </Row>
            </Col>
            <Col width={'1px'} height={'80px'} background={COLOR_V4.GRAY200} margin={'29px 0 0'} />
            <Col width={'calc(50% - 1px)'} padding={'28px 27px 29px 22px'}>
                {idx === '' ? (
                    <Row>
                        <Col width={'70%'} margin={'10px 0 0'}>
                            <Heading1 color={COLOR_V4.GRAY900}>
                                집꾸미기 신규 가입 혜택
                                <br />
                                <b>5% 할인 쿠폰</b> 지급
                            </Heading1>
                        </Col>
                        <Col width={'30%'} height={'86px'} align={'flex-end'} justify={'flex-end'}>
                            <Button
                                width={'146px'}
                                height={'40px'}
                                borderRadius={'50px'}
                                background={COLOR_V4.PINK500}
                                onClick={() => router.push('/login')}
                            >
                                <Heading1 color={COLOR_V4.WHITE} fontWeight={'bold'}>
                                    가입하기
                                </Heading1>
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Col>
                        <Row justify={'center'} align={'center'} margin={'0 0 10px 6px'}>
                            <MemberThumbnail>
                                <img
                                    src={
                                        recentProfile !== ''
                                            ? `https://cdn.ggumim.co.kr/cache/member/profile/180/${recentProfile}`
                                            : `https://cdn.ggumim.co.kr/storage/20210708182007wM3nkRdM5x.png`
                                    }
                                    alt={'user_profile'}
                                />
                            </MemberThumbnail>
                            <Col grow={1}>
                                <Heading1 color={COLOR_V4.GRAY900}>
                                    <b>{addNicknameEllipsis(nickname)}</b> 님의 집꾸미기
                                </Heading1>
                            </Col>
                            <Col width={'82px'}>
                                <Button
                                    width={'82px'}
                                    height={'32px'}
                                    background={COLOR_V4.GRAY100}
                                    borderRadius={'50px'}
                                    onClick={() => go('/member/setting')}
                                >
                                    <Body4 color={COLOR_V4.GRAY400} fontWeight={'bold'}>
                                        프로필 수정
                                    </Body4>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <SectionRow>
                                <Col>
                                    <GrayText>
                                        <img
                                            src={
                                                '//cdn.ggumim.co.kr/storage/202202181031538YlTKSmTck.png'
                                            }
                                            alt={'grade'}
                                        />
                                        등급
                                    </GrayText>
                                </Col>
                                <Col>
                                    <Text color={COLOR_V4.PINK500}>{gradeName}</Text>
                                </Col>
                            </SectionRow>
                            <Row
                                width={'1px'}
                                height={'18px'}
                                margin={'9px 10px'}
                                background={COLOR_V4.GRAY200}
                            />
                            <SectionRow>
                                <Col>
                                    <GrayText>
                                        <img
                                            src={
                                                '//cdn.ggumim.co.kr/storage/20220218103159fiVzWvCH0z.png'
                                            }
                                            alt={'coupon'}
                                        />
                                        쿠폰
                                    </GrayText>
                                </Col>
                                <Col>
                                    <Text color={COLOR_V4.GRAY900}>{couponCount}</Text>
                                </Col>
                            </SectionRow>
                            <Row
                                width={'1px'}
                                height={'18px'}
                                margin={'9px 10px'}
                                background={COLOR_V4.GRAY200}
                            />
                            <SectionRow>
                                <Col>
                                    <GrayText>
                                        <img
                                            src={
                                                '//cdn.ggumim.co.kr/storage/20220218103206aa14Fwlf2M.png'
                                            }
                                            alt={'point'}
                                        />
                                        포인트
                                    </GrayText>
                                </Col>
                                <Col>
                                    <Text color={COLOR_V4.GRAY900}>
                                        {point ? point.toLocaleString() : 0}
                                    </Text>
                                </Col>
                            </SectionRow>
                        </Row>
                    </Col>
                )}
            </Col>
        </ShortCutContainer>
    );
};

export default ShortCut;
