import { DELIVERY_TYPE, DELIVERY_PAYMENT, DELIVERY_LOCAL_TYPE } from './values';

export function formatCountUnit(count: number) {
    if (count >= 10000) {
        return `${(count / 10000).toFixed(1)}만`;
    } else if (count >= 1000) {
        return `${(count / 1000).toFixed(1)}천`;
    } else if (count >= 100) {
        return `${(count / 100).toFixed(1)}백`;
    } else {
        return `${count}`;
    }
}

export function getDeliveryPrice(type = 0, price = 0, condition = 0, payment = 0) {
    let text = '';

    switch (type) {
        case DELIVERY_TYPE.FIXED:
        case DELIVERY_TYPE.CONDITION_1:
        case DELIVERY_TYPE.COUNT_1:
            if (price === 0) {
                text = payment !== DELIVERY_PAYMENT.POST ? '무료' : '착불(상품상세 참조)';
            } else {
                text = `${price.toLocaleString()}원`;
            }
            break;
        case DELIVERY_TYPE.FREE:
            text = payment !== DELIVERY_PAYMENT.POST ? '무료' : '착불(상품상세 참조)';
            break;
        case DELIVERY_TYPE.PROPORTION:
            text =
                payment === DELIVERY_PAYMENT.POST && price === 0
                    ? '착불(상품상세 참조)'
                    : `개당 ${price.toLocaleString()}원`;
            break;
        case DELIVERY_TYPE.CONDITION:
            text = `${price.toLocaleString()}원 (${condition.toLocaleString()}원 이상 구매하면 배송비 무료!)`;
            break;
        case DELIVERY_TYPE.COUNT:
            text = `${price.toLocaleString()}원 (${condition.toLocaleString()}개 이상 구매하면 배송비 무료!)"`;
            break;
    }

    return text;
}

export function getDeliveryLocalPrice(
    useCommerceDeliveryLocalPayment = false,
    commerceDeliveryLocalType = 0,
    commerceDeliveryLocalPrice = 0,
) {
    if (useCommerceDeliveryLocalPayment) {
        if (commerceDeliveryLocalType === DELIVERY_LOCAL_TYPE.POST) {
            return `상품상세 참조`;
        } else {
            if (commerceDeliveryLocalPrice > 0) {
                return `제주/도서산간 ${commerceDeliveryLocalPrice.toLocaleString()}원 추가`;
            } else {
                return `상품상세 참조`;
            }
        }
    } else {
        return `배송 불가`;
    }
}
