import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatCountUnit } from '../../lib/helper';

import { Row, Col, Text, Wrapper, GridWrapper, Body2, Body1, Heading1 } from '../layout';
import { SectionTitle } from './SectionTitle';
import { getHomeYoutubeList } from '../../api/home';
import { COLOR_V4 } from '../../lib/styles/colors';

interface IThumbnailProps {
    image?: string;
    width?: string;
}

interface IYoutubeItemProps {
    thumbnail: string;
    url: string;
    title: string;
    description: string;
    likeCount: number;
    viewCount: number;
}

const YoutubeDiv = styled(Col)<IThumbnailProps>`
    width: 100%;
    justify-content: center;
    align-items: center;
    ${({ image }) => image && `background-image: url('${image}');`}
    background-position: center;
    background-size: cover;
    border-radius: 7px;
    overflow: hidden;

    .play,
    .sub-play {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
        z-index: 100;
    }

    .play {
        width: 66px;
        height: 66px;

        img {
            margin-left: 4px;
            width: 26px;
            height: 30px;
        }
    }

    .sub-play {
        width: 34px;
        height: 34px;

        img {
            margin-left: 4px;
            width: 14px;
            height: 16px;
        }
    }
`;

const MainYoutubeHoverRow = styled(Row)`
    width: 690px;
    height: 520px;
    border-radius: 21px;
    margin: 0 24px 0 0;
    background: transparent;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;

    &:hover {
        background: ${COLOR_V4.GRADIENT4};
    }
`;

const MainYoutubeCol = styled(Col)`
    margin: 2px;
    width: 686px;
    height: 516px;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0px 2px 8px rgba(100, 111, 124, 0.2);

    ${YoutubeDiv} {
        border-radius: 20px 20px 0 0;
    }

    .description {
        padding: 24px 28px 33px;
        border-radius: 0 0 20px 20px;
        background: #ffffff;
    }
`;

const SubYoutubeHoverRow = styled(Row)`
    width: 100%;
    border-radius: 20px;
    margin: 0 0 24px;
    background: ${COLOR_V4.WHITE};
    box-shadow: 0px 2px 8px rgba(100, 111, 124, 0.2);
    cursor: pointer;
    z-index: 10;

    &:hover {
        background: ${COLOR_V4.GRADIENT4};
    }
`;

const SubYoutubeRow = styled(Row)`
    margin: 2px;
    padding: 23px 20px;
    border-radius: 18px;
    background: white;
    z-index: 100;
`;

const TitleText = styled(Text)`
    max-height: 24px;
    color: ${COLOR_V4.GRAY500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`;

function YoutubeList() {
    const [youtubeList, setYoutubeList] = useState<IYoutubeItemProps[]>([]);
    let mainYoutubeItem: IYoutubeItemProps = {
        thumbnail: '',
        url: '',
        title: '',
        description: '',
        likeCount: 0,
        viewCount: 0,
    };

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getYoutubeList() {
            const returnData = await getHomeYoutubeList();
            setYoutubeList(returnData);
        }

        getYoutubeList();
    }, []);

    if (youtubeList.length === 0) {
        return <></>;
    } else {
        mainYoutubeItem = youtubeList[0];
    }

    return (
        <Wrapper overscroll={'contain'} background={'#eef3fa'} padding={'50px 0'}>
            <GridWrapper>
                <SectionTitle
                    title={'집꾸TV'}
                    isShowButton={true}
                    onClickEvent={() => {
                        window.open(
                            'https://www.youtube.com/c/%EC%A7%91%EA%BE%B8%EB%AF%B8%EA%B8%B0%EC%9C%A0%ED%8A%9C%EB%B8%8C',
                            '_blank',
                        );
                    }}
                />
                <Row>
                    {youtubeList.length !== 0 && (
                        <MainYoutubeHoverRow>
                            <MainYoutubeCol
                                onClick={() => {
                                    window.open(mainYoutubeItem.url, '_blank');
                                }}
                            >
                                <YoutubeDiv
                                    width={'690px'}
                                    height={'376px'}
                                    image={mainYoutubeItem.thumbnail}
                                >
                                    <div className={'play'}>
                                        <img
                                            src="//cdn.ggumim.co.kr/storage/20211103132352C7uux7PcEY.png"
                                            alt=""
                                        />
                                    </div>
                                </YoutubeDiv>
                                <Col className={'description'}>
                                    <Row margin={'0 0 6px'}>
                                        <Heading1 color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                                            {mainYoutubeItem.title}
                                        </Heading1>
                                    </Row>
                                    <Row margin={'0 0 16px'}>
                                        <TitleText
                                            color={COLOR_V4.GRAY400}
                                            fontSize={'16px'}
                                            fontWeight={'medium'}
                                            lineHeight={'24px'}
                                        >
                                            {mainYoutubeItem.description}
                                        </TitleText>
                                    </Row>
                                    <Row>
                                        <Col margin={'-1px 7px 0 0'}>
                                            <img
                                                src="//cdn.ggumim.co.kr/storage/20220324161338ExmqHVqTpc.png"
                                                alt=""
                                                width={16}
                                            />
                                        </Col>
                                        <Col>
                                            <Body2 color={COLOR_V4.GRAY300} fontWeight={'bold'}>
                                                {formatCountUnit(mainYoutubeItem.likeCount)}
                                            </Body2>
                                        </Col>
                                        <Col
                                            width={'1px'}
                                            margin={'1px 10px'}
                                            background={COLOR_V4.GRAY300}
                                        ></Col>
                                        <Col>
                                            <Body2 color={COLOR_V4.GRAY300} fontWeight={'bold'}>
                                                조회수 {formatCountUnit(mainYoutubeItem.viewCount)}
                                            </Body2>
                                        </Col>
                                    </Row>
                                </Col>
                            </MainYoutubeCol>
                        </MainYoutubeHoverRow>
                    )}
                    <Col width={'calc(1000px - 714px)'} margin={'2px 0 0'}>
                        {youtubeList.map((item: any, index: number) => {
                            const {
                                idx,
                                title,
                                thumbnail,
                                description,
                                likeCount,
                                viewCount,
                                url,
                            } = item;
                            // 첫번째 아이템은 크게 보여주기 때문
                            if (index === 0) {
                                return null;
                            }

                            return (
                                <SubYoutubeHoverRow key={`zipgguTv-item-${idx}`}>
                                    <SubYoutubeRow onClick={() => window.open(url, '_blank')}>
                                        <Col width={'190px'} height={'106px'}>
                                            <YoutubeDiv>
                                                <img src={thumbnail} alt="" />
                                                <div className={'sub-play'}>
                                                    <img
                                                        src="//cdn.ggumim.co.kr/storage/20211103132352C7uux7PcEY.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </YoutubeDiv>
                                        </Col>
                                        <Col width={'calc(100% - 210px)'} margin={'0 0 0 20px'}>
                                            <Row padding={'2px 0 8px'}>
                                                <Body1
                                                    hideText={true}
                                                    clamp={1}
                                                    color={COLOR_V4.GRAY900}
                                                    fontWeight={'bold'}
                                                >
                                                    {title}
                                                </Body1>
                                            </Row>
                                            <Row width={'100%'} margin={'0 0 13px'}>
                                                <Body2
                                                    hideText={true}
                                                    height={'41px'}
                                                    overflow={'hidden'}
                                                    color={COLOR_V4.GRAY400}
                                                    fontWeight={'medium'}
                                                    whiteSpace={'pre'}
                                                >
                                                    {description}
                                                </Body2>
                                            </Row>
                                            <Row>
                                                <Col margin={'-1px 7px 0 0'}>
                                                    <img
                                                        src="//cdn.ggumim.co.kr/storage/20220324161338ExmqHVqTpc.png"
                                                        alt=""
                                                        width={16}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Body2
                                                        color={COLOR_V4.GRAY300}
                                                        fontWeight={'bold'}
                                                    >
                                                        {formatCountUnit(likeCount)}
                                                    </Body2>
                                                </Col>
                                                <Col
                                                    width={'1px'}
                                                    margin={'3px 10px'}
                                                    background={COLOR_V4.GRAY300}
                                                ></Col>
                                                <Col>
                                                    <Body2
                                                        color={COLOR_V4.GRAY300}
                                                        fontWeight={'bold'}
                                                    >
                                                        조회수 {formatCountUnit(viewCount)}
                                                    </Body2>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </SubYoutubeRow>
                                </SubYoutubeHoverRow>
                            );
                        })}
                    </Col>
                </Row>
            </GridWrapper>
        </Wrapper>
    );
}

export default YoutubeList;
